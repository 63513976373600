import React, { useState, useEffect, useRef } from 'react';
import { HiCheck } from "react-icons/hi";
import { SlPencil, SlTrash } from "react-icons/sl";
import './App-dashboard.css';
import { SlPlus, SlLogout, SlClose } from "react-icons/sl";
import { getUsers, createUser, updateUser, deleteUser } from './api'; // Import API functions
import Select from 'react-select'; // Ensure this is imported at the top of your file
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const Dashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([
  ]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newName, setNewName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newRole, setNewRole] = useState('');
  const containerRef = useRef(null);

  const roleOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'User', label: 'User' },
    { value: 'Limited User', label: 'Limited User' }

  ];
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333333',
      color: '#e0e0e0',
      border: '1px solid #757575',
      borderRadius: '4px',
      height: '4vw',
      minHeight: '30px', // Adjust based on your design needs
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#ff616d',
      },
      zIndex: 9999,
    }),
    input: (provided) => ({
      ...provided,
      color: '#e0e0e0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#e0e0e0',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333333',
      color: '#e0e0e0',
      border: 'none',
      zIndex: 9999,
      overflow: 'visible'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#424242' : '#333333',
      color: '#e0e0e0',
      '&:hover': {
        backgroundColor: '#424242',
      },
      zIndex: 9999,
      overflow: 'visible'
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  // Function to find the role option based on the current role value
  const getRoleOption = (role) => roleOptions.find(option => option.value === role);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [users.length]); // Triggered whenever the users array changes length
  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await getUsers();
      // Sort users by ID in ascending order
      const sortedUsers = fetchedUsers.sort((a, b) => a.id - b.id);
      const usersWithFlag = sortedUsers.map(user => ({ ...user, isNew: false }));
      setUsers(usersWithFlag);
    };
    fetchUsers();
  }, []);
  const handleLogout = () => {
    // Implement logout logic here, e.g., clearing user data
    navigate('/'); // Redirect to login page
  };
  const handleAddUser = () => {
    const newUser = {
      id: users.length + 1,
      name: newName,
      password: newPassword,
      email: newEmail,
      role: newRole,
      isNew: true // Mark as new user

    };
    setUsers([...users, newUser]);
    setEditingIndex(users.length); // Set editing index to new user
    setNewName('');
    setNewPassword('');
    setNewEmail('');
    setNewRole('');
  };

  const handleEditUser = (index) => {
    setEditingIndex(index);
    const user = users[index];
    setNewName(user.name);
    setNewPassword(user.password);
    setNewEmail(user.email);
    setNewRole(user.role);
  };

  const handleConfirmEdit = async () => {
    if (!newName || !newPassword || !newEmail || !newRole) {
      alert('All fields must be filled out.');
      return;
    }

    const user = {
      name: newName,
      password: newPassword,
      email: newEmail,
      role: newRole
    };

    let updatedUsers = [...users];
    const isCurrentUserNew = users[editingIndex].isNew;

    if (isCurrentUserNew) {
      // Create new user
      const newUser = await createUser(user);
      newUser.isNew = false; // Update isNew flag
      updatedUsers[editingIndex] = newUser; // Replace temporary user data with real data from backend
    } else {
      // Update existing user
      const updatedUserData = await updateUser(users[editingIndex].id, user);
      updatedUsers[editingIndex] = updatedUserData;
    }

    setUsers(updatedUsers);
    setEditingIndex(null);
    setNewName('');
    setNewPassword('');
    setNewEmail('');
    setNewRole('');
  };

  const handleCancelEdit = () => {
    // Reset the form fields if necessary
    setNewName('');
    setNewPassword('');
    setNewEmail('');
    setNewRole('');
    // Exit edit mode
    setEditingIndex(null);
    // Remove the row if it was a new user
    if (users[editingIndex].isNew) {
      setUsers(users.filter((_, index) => index !== editingIndex));
    }
  };

  const handleRemoveUser = async (index) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      await deleteUser(users[index].id);
      setUsers(users.filter((_, i) => i !== index));
    }
  };

  return (
    <>
      <div className="App">
        <div className="header">
          <h1><span className="titleLetters">D</span>ragon<span className="titleLetters">S</span>couter</h1>
        </div>
        <div className="admin-dashboard">
          <div className="projects-div2">
            <div className="users-container">
              <h2>User Management</h2>


              <div ref={containerRef} className="users-table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Password</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user.id}>
                        <td>{editingIndex === index ? (
                          <input
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                          />
                        ) : user.name}</td>
                        <td>{editingIndex === index ? (
                          <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        ) : '••••••••'}</td>
                        <td>{editingIndex === index ? (
                          <input
                            className="email-input"
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                          />
                        ) : user.email}</td>
                        <td>{editingIndex === index ? (
                          <Select
                            styles={customSelectStyles}
                            options={roleOptions}
                            onChange={(selectedOption) => setNewRole(selectedOption.value)}
                            value={getRoleOption(newRole)}
                            menuPortalTarget={document.body} // Add this line

                          />
                        ) : user.role}</td>
                        <td>
                          {editingIndex === index ? (<>
                            <button className="confirm-button" onClick={handleConfirmEdit}><HiCheck /></button>
                            <button className="delete-button" onClick={handleCancelEdit}><SlClose /></button>
                          </>

                          ) : (
                            <>
                              <button className="edit-button" onClick={() => handleEditUser(index)}><SlPencil /></button>
                              <button className="delete-button" onClick={() => handleRemoveUser(index)}><SlTrash /></button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="sticky-row">
                        <button className="add-user-button" onClick={handleAddUser}><SlPlus /> ADD NEW USER</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="footer-admin">
            <button onClick={handleLogout} className="logout-button"><SlLogout /> </button> {/* Add this line for logout */}
          </div>
        </div>

      </div >
    </>
  );

};

export default Dashboard;