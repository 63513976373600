import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Sourcing from './Sourcing'; // Make sure these paths are correct
import Project from './Project'; // Make sure these paths are correct
import AdminDashboard from './AdminDashboard'; // Make sure these paths are correct
import { getLogin, getMe } from './api'; // Import the getLogin function from api.js
import './App-login.css';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the form from being submitted to the server

    try {
      const loginResponse = await getLogin(username, password);

      // Assuming loginResponse.token exists on successful login
      if (loginResponse.token) {
        console.log('Login successful:', loginResponse);
        localStorage.setItem('token', loginResponse.token); // Store the token in local storage
        const userDetails = await getMe(); // Retrieve user details
        console.log('User details:', userDetails);
        if (userDetails.role === 'Admin') {
          navigate('/AdminDashboard'); // Navigate to the dashboard on successful login
        } else {
          navigate('/Project'); // Navigate to the projects page on successful login
        }
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please try again later.');
    }
  };

  return (
    <div className="app-container">

      <div className="login-container">
        <form onSubmit={handleLogin}>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/sourcing" element={<Sourcing />} />
        <Route path="/sourcing/:projectId" element={<Sourcing />} />
        <Route path="/Project" element={<Project />} />
        <Route path="/AdminDashboard" element={<AdminDashboard />} />
      </Routes>
    </Router>
  );
};

export default App;