import axios from "axios";
import { BASE_URL } from "./config";
const API_URL = BASE_URL;
const api = axios.create({
  baseURL: API_URL,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const getProject = async (projectId) => {
  try {
    const response = await api.get(`${API_URL}/projects/${projectId}`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch project:", error);
    throw error;
  }
};

export const getProjects = async () => {
  try {
    const response = await api.get(`${API_URL}/projects`);
    console.log(`Full URL: ${API_URL}/projects`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch projects:", error);
    throw error;
  }
};

export const getProjectsByUser = async (userId, skip = 0, limit = 100) => {
  try {
    const response = await api.get(`${API_URL}/users/${userId}/projects`, {
      params: { skip, limit },
    });
    console.log(`Full URL: ${API_URL}/users/${userId}/projects?skip=${skip}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch projects by user:", error);
    throw error;
  }
};

export const createProject = async (projectData) => {
  try {
    const response = await api.post(`${API_URL}/projects`, projectData);
    //show the full URL, and the projectData
    console.log(`Full URL: ${API_URL}/projects`);
    console.log(`Project Data: ${projectData}`);
    return response.data;
  } catch (error) {
    console.error("Failed to create project:", error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await api.delete(`${API_URL}/projects/${projectId}`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete project:", error);
    throw error;
  }
};

export const updateProject = async (projectId, projectData) => {
  try {
    const response = await api.put(
      `${API_URL}/projects/${projectId}`,
      projectData
    );
    console.log(`Full URL: ${API_URL}/projects/${projectId}`);
    console.log(`Project Data: ${projectData}`);
    return response.data;
  } catch (error) {
    console.error("Failed to update project:", error);
    throw error;
  }
};

export const getSourcingsByProject = async (projectId) => {
  try {
    const response = await api.get(
      `${API_URL}/projects/${projectId}/sourcings`
    );
    console.log(`Full URL: ${API_URL}/projects/${projectId}/sourcings`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch sourcings by project:", error);
    throw error;
  }
};
export const generateAIKeywords = async (
  arrayInput,
  countryInput,
  languageInput
) => {
  try {
    const response = await api.post(
      `${API_URL}/generate_ai_keywords`,
      {
        array_input: arrayInput,
        country_input: countryInput,
        language_input: languageInput,
      },
      {
        timeout: 600000, // 600000ms = 10 minutes
      }
    );
    console.log("AI Keywords generated successfully");
    return response.data;
  } catch (error) {
    console.error("Failed to generate AI keywords:", error);
    throw error;
  }
};
export const updateProjectStatus = async (projectId, status) => {
  try {
    const response = await api.patch(
      `${API_URL}/projects/${projectId}/status`,
      {
        status: status,
      }
    );
    console.log(
      `Project status updated successfully for project ID: ${projectId} with status: ${status}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update project status:", error);
    throw error;
  }
};

export const getLastSourcingByProject = async (projectId) => {
  try {
    const response = await api.get(
      `${API_URL}/projects/${projectId}/sourcings/last`
    );
    console.log(`Full URL: ${API_URL}/projects/${projectId}/sourcings/last`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch last sourcing by project:", error);
    throw error;
  }
};

export const getProjectProgress = async (projectId) => {
  try {
    const response = await api.get(`${API_URL}/projects/${projectId}/progress`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}/progress`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch project progress:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await api.get(`${API_URL}/users`);
    console.log(`Full URL: ${API_URL}/users`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch users:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post(`${API_URL}/users`, userData);
    console.log(`Full URL: ${API_URL}/users`);
    console.log(`User Data: ${JSON.stringify(userData)}`);
    return response.data;
  } catch (error) {
    console.error("Failed to create user:", error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`${API_URL}/users/${userId}`, userData);
    console.log(`Full URL: ${API_URL}/users/${userId}`);
    console.log(`User Data: ${JSON.stringify(userData)}`);
    return response.data;
  } catch (error) {
    console.error("Failed to update user:", error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`${API_URL}/users/${userId}`);
    console.log(`Full URL: ${API_URL}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete user:", error);
    throw error;
  }
};

export const getLogin = async (username, password) => {
  try {
    const authData = new URLSearchParams();
    authData.append("username", username);
    authData.append("password", password);

    const response = await api.post(`${API_URL}/login`, authData);
    return response.data;
  } catch (error) {
    console.error("Failed to login:", error);
    throw error;
  }
};

export const getMe = async () => {
  try {
    const response = await api.get(`${API_URL}/me`);
    console.log(`Full URL: ${API_URL}/me`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    throw error;
  }
};
