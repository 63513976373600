import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'; import { ProgressBar } from "react-progressbar-fancy";
import Sourcing from './Sourcing'; // Make sure this component is set up for routing
import './App-login.css';
import { SlEye, SlLogout, SlBriefcase, SlControlPause, SlControlPlay } from "react-icons/sl";
import { getProjects, getProjectsByUser, getMe } from './api'; // Import the getProjects function from the api.js file
import { TbUserExclamation } from "react-icons/tb";
import { HiCheck } from "react-icons/hi2";

const AutomatedSourcingTool = () => {
  const navigate = useNavigate(); // Add this line

  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [projectsToRemove, setProjectsToRemove] = useState([]); // New state to track projects being removed

  const handleRemoveProject = (projectId) => {
    if (window.confirm("Are you sure you want to archive this project?")) {
      setProjectsToRemove([...projectsToRemove, projectId]); // Add project to removal list
    }
  };

  const handleAddNewProject = () => {
    navigate('/sourcing');
  };

  const handleDisconnect = () => {
    navigate('/'); // Assuming '/login' is the route for the login page
  };

  const fetchProjects = async () => {
    try {
      const userDetails = await getMe(); // Retrieve user details
      console.log('User details:', userDetails);
      let projects = [];
      if (userDetails.role === 'User') {
        projects = await getProjects();
      } else if (userDetails.role === 'Limited User') {
        projects = await getProjectsByUser(userDetails.id);
      }
      const transformedProjects = projects.map(project => ({
        id: project.id,
        status: project.status,
        charge_code: project.charge_code,
        date: project.start_date,
        client: project.client_name,
        description: project.category,
        progress: project.progress
      }));
      const ongoing = transformedProjects.filter(project => project.progress < 100);
      const completed = transformedProjects.filter(project => project.progress === 100);

      setOngoingProjects(ongoing);
      setCompletedProjects(completed);
    } catch (error) {
      console.error("Failed to fetch and transform projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects(); // Initial fetch on component mount

    const interval = setInterval(() => {
      fetchProjects(); // Fetch projects every minute
    }, 60000); // 60000 ms = 1 minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Empty dependency array means this runs once on component mount

  useEffect(() => {
    // This useEffect is for handling project removals
    if (projectsToRemove.length > 0) {
      const timer = setTimeout(() => {
        setCompletedProjects(completedProjects.filter(project => !projectsToRemove.includes(project.id)));
        setProjectsToRemove([]); // Reset the removal list
      }, 500); // Set timeout to match the animation duration

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [projectsToRemove]);

  return (
    <div className="App">
      <div className="header">
        <h1><span className="titleLetters">D</span>ragon<span className="titleLetters">S</span>couter</h1>
      </div>
      <div className="automated-sourcing-tool">
        <div className="projects-div">
          <div className="projects-container projects-table">
            <div className="add-projects">
              <button onClick={handleDisconnect} className="disconnect-button"><SlLogout /></button> {/* Moved and modified this line */}
              <button className="add-project-button" onClick={handleAddNewProject}>ADD NEW PROJECT</button>
            </div>
            <div className="ongoing-projects">
              <h2>ONGOING PROJECTS</h2>
              <table>
                <tbody>
                  {ongoingProjects.map(project => (
                    <tr key={project.id} style={{ cursor: 'pointer' }} onClick={() => navigate(`/sourcing/${project.id}`, { state: { loadedProj: project.id } })}>
                      <td>  {project.status === 'PAUSED' && <SlControlPause />}
                        {project.status === 'ONGOING' && <SlControlPlay />}
                        {project.status === 'ONGOING_CONFIRM' && <SlControlPlay />}
                        {project.status === 'COMPLETED' && <HiCheck />}
                        {project.status === 'CONFIRM' && <TbUserExclamation />}
                      </td>
                      <td>{project.charge_code}</td>
                      <td>{project.date}</td>
                      <td>{project.client}</td>
                      <td>{project.description}</td>
                      <td>
                        <p>{project.progress}%</p>
                        <div style={{ width: `${project.progress}%`, height: '4px', backgroundColor: 'red' }}></div>
                      </td>
                      {/*                       <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <SlEye className="see-icons" onClick={() => navigate(`/sourcing/${project.id}`, { state: { loadedProj: project.id } })} />
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="completed-projects">
              <h2>COMPLETED PROJECTS</h2>
              <table>
                <tbody>
                  {completedProjects.map(project => (
                    <tr key={project.id} style={{ cursor: 'pointer' }} onClick={() => navigate(`/sourcing/${project.id}`, { state: { loadedProj: project.id } })}>
                      <td>{project.status === 'COMPLETED' && <HiCheck />}
                      </td>
                      <td>{project.charge_code}</td>
                      <td>{project.date}</td>
                      <td>{project.client}</td>
                      <td>{project.description}</td>
                      <td>
                        <p>{project.progress}%</p>
                        <div style={{ width: `${project.progress}%`, height: '4px', backgroundColor: 'red' }}></div>
                      </td>
                      {/*                       <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <SlEye className="see-icons" onClick={() => navigate(`/sourcing/${project.id}`)} />
                          <SlBriefcase className="archive-icons" onClick={() => handleRemoveProject(project.id)} />
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

const Project = () => {
  return (
    <Routes>
      <Route path="/" element={<AutomatedSourcingTool />} />
      <Route path="/sourcing" element={<Sourcing />} />
      <Route path="/sourcing/:projectId" element={<Sourcing />} />
      {/* Define other routes here */}
    </Routes>
  );
};

export default Project;